import React from 'react';
import logo from '../../assets/logo.png'
import ifood from '../../assets/ifood.png'
import whatsapp from '../../assets/wpp.png'
import fish from '../../assets/fish.png'
import bowl from '../../assets/bowl.png'
import farinha from '../../assets/farinha.png'
import Option from '../../components/Option';
import CircleCard from '../../components/CircleCard';
import { FaBars } from 'react-icons/fa';

import './style.css'

export default function Home() {
    return (
        <div className="container">
            <div className="section-hero">
                <header className="section-hero-content">
                    <img src={logo} alt="Logo do Peixe sem espinha" />
                    <div id="menu-icon">
                        <FaBars />
                    </div>
                    <nav className="section-hero-links">
                        <ul>
                            <li><a href="#menu">Cardápio</a></li>
                            <li><a href="#contacts">Contatos</a></li>
                            <li><a href="#about">Sobre</a></li>
                        </ul>
                    </nav>
                </header>
                <div className="hero-text-content">
                    <h1>
                        O melhor <span>peixe assado</span> sem espinha de Macapá
                    </h1>
                    <a className="button ifood" href="https://www.ifood.com.br/delivery/macapa-ap/peixe-sem-espinha-trem">
                        Pedir pelo Ifood <img src={ifood} alt="Peça Peixe sem espinha pelo Ifood" />
                    </a>
                    <a className="button whatsapp " href="https://api.whatsapp.com/send?phone=5596991046636">
                        Pedir pelo whatsapp <img src={whatsapp} alt="Peça Peixe sem espinha pelo Whatsapp" />
                    </a>
                </div>
            </div>
            <a id="menu"></a>
            <div className="section-menu">
                <Option image="https://i.imgur.com/wiK4IJL.jpg" title="Pirapitinga assada" description="Peixe assado sem espinha" weight="538g" extra="kit molho com tucupi, limão e pimenta" />
                <Option image="https://i.imgur.com/wiK4IJL.jpg" title="Pirapitinga assada" description="Peixe assado sem espinha" weight="538g" extra="kit molho com tucupi, limão e pimenta" />
                <Option image="https://i.imgur.com/wiK4IJL.jpg" title="Pirapitinga assada" description="Peixe assado sem espinha" weight="538g" extra="kit molho com tucupi, limão e pimenta" />
            </div>
            <div className="section-slogan">
                <CircleCard image={fish} title="O melhor peixe de Macapá" />
                <CircleCard image={bowl} title="Açaí especial do grosso" />
                <CircleCard image={farinha} title="Farinha estralando de torrada" />
            </div>
        </div>
    )
}