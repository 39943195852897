import React from 'react';

export default function Option(props){
    return(
        <div className="menu-option">
            <img alt="Opção de menu do Peixe sem espinha" src={props.image} />
            <div className="option-content">
                <h3>{props.title}</h3>
                <p>{props.description}</p>
                <div className="more-information">
                    <p>{props.weigth}</p>
                    <p>{props.extra}</p>
                </div>
            </div>
        </div>
    )
}